import React from 'react'
import { Link } from 'gatsby'
import { withPrefix } from 'gatsby'

const ShopLink = class extends React.Component {
  render() {
    return (
      <Link to="https://store.plat-inc.com/" className="shop-link">
        <img
          src= {`${withPrefix('/')}img/croissant-link.png`}
          srcSet={`
            ${withPrefix('/')}img/croissant-link@2x.png 2x,
            ${withPrefix('/')}img/croissant-link@3x.png 3x
          `}
          alt="クロワッサンショップ"
          className="shop-link__image"
        />
      </Link>
    );
  }
}

export default ShopLink

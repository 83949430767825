import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import Slider from 'react-slick'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './carousel.css'

class CarouselArrow extends React.Component {
  render() {
    const { type, onClick } = this.props
    return (
      <div
        onClick={onClick}
        className={`carousel__arrow ${
          type === 'prev' ? 'carousel__arrow--prev' : 'carousel__arrow--next'
        }`}
        tabIndex="0"
      >
        <div className="carousel__arrow-inner"></div>
      </div>
    )
  }
}

class PreviousArrow extends React.Component {
  render() {
    const { onClick } = this.props
    return <CarouselArrow type="prev" onClick={onClick}></CarouselArrow>
  }
}

class NextArrow extends React.Component {
  render() {
    const { onClick } = this.props
    return <CarouselArrow type="next" onClick={onClick}></CarouselArrow>
  }
}

class Carousel extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    const list = posts ? posts : []
    const topImageItems = list.filter(({ node: post }) => {
      const frontmatter = post.frontmatter
      return frontmatter.topimage ? true : false
    })

    const settings = {
      dots: false,
      infinite: true,
      fade: true,
      arrows: true,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 3500,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <PreviousArrow />,
      nextArrow: <NextArrow />,
    }

    return (
      <div className="cover__background">
        <div className="cover__background-inner">
          <Slider {...settings}>
            {topImageItems.map(({ node: post }) => (
              <Link key={post.id} to={`${post.fields.slug}?from=top`}>
                <PreviewCompatibleImage
                  imageInfo={{
                    image: post.frontmatter.topimage,
                    alt: post.title,
                  }}
                  className="cover__background-image"
                />
              </Link>
            ))}
          </Slider>
        </div>
      </div>
    )
  }
}

Carousel.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query CarouselQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "works-page" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                topimage {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <Carousel data={data} count={count} />}
  />
)
